import { useLazyQuery } from '@apollo/client';
import get from 'lodash/get';
import { useSession } from 'next-auth/react';
import dynamic from 'next/dynamic';
import React, { useEffect, useMemo, useState } from 'react';
import {
  MATOMO_CATEGORY,
  PAGE_TYPE,
  SESSION_STATUS,
  STATIC_PAGES,
  TRACK_VALUE
} from '../common/constants';
import useTrackPageEvent from '../common/useTrackPageEvent';
import useWatchTime from '../common/useWatchTime';
import MODULES, {
  getMetaOgImage,
  getUniqueVideoPodcastIds,
  jsonDataApi
} from '../common/utils';
import CustomMeta from '../components/CustomMeta';
import ModuleComponent from '../components/ModuleComponents';
import { GET_PAGE_V2 } from '../graphql/Queries';

const MetaFooter = dynamic(() => import('../components/MetaFooter'), {
  loading: () => null,
  ssr: false
});

function Modules({
  page: {
    modules: pageData = [],
    title,
    description,
    metaImage,
    metaHeader,
    metaFooter,
    slug,
    type,
    id: pageId
  } = {}
}) {
  const { data, status } = useSession();

  const [modules, setModules] = useState(() =>
    pageData?.map((module) => ({
      ...MODULES[module?.type]?.(module),
      isLoading: !!module?.showSkeleton
    }))
  );

  const [fetchPage] = useLazyQuery(GET_PAGE_V2, {
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        token: data?.accessToken
      }
    },
    onCompleted: (page) => {
      if (page) {
        const moduleObject = page?.pageV2?.modules?.reduce((acc, module) => {
          acc[module?.id] = {
            ...MODULES[module?.type]?.(module)
          };
          return acc;
        }, {});
        setModules((prev) =>
          prev
            .filter(({ id, isLoading }) => !(isLoading && !moduleObject?.[id]))
            .map((module) => ({
              ...module,
              ...moduleObject?.[module?.id],
              isLoading: false
            }))
        );
      }
    }
  });

  const permissionBasedModulesIds = useMemo(
    () =>
      pageData
        ?.filter((module) => !!module?.showSkeleton)
        ?.map((module) => module?.id),
    [pageData]
  );

  useEffect(() => {
    setModules(
      pageData?.map((module) => {
        return {
          ...MODULES[module?.type]?.(module),
          isLoading: !!module?.showSkeleton
        };
      })
    );
  }, [pageData]);

  useTrackPageEvent({
    id: pageId,
    type,
    slug,
    title,
    category: MATOMO_CATEGORY.VIEW_DETAIL,
    statusSuccess: TRACK_VALUE.SUCCESS
  });

  useEffect(() => {
    if (
      status !== SESSION_STATUS.LOADING &&
      permissionBasedModulesIds.length > 0
    ) {
      setModules(
        pageData?.map((module) => ({
          ...MODULES[module?.type]?.(module),
          isLoading: !!module?.showSkeleton
        }))
      );
      fetchPage({
        variables: {
          where: {
            type: PAGE_TYPE?.CUSTOM,
            slug: STATIC_PAGES.HOME
          },
          filter: {
            moduleIds: permissionBasedModulesIds
          }
        }
      });
    }
  }, [status, permissionBasedModulesIds, fetchPage, pageData, slug]);

  const { videoIds, podcastIds } = useMemo(
    () => getUniqueVideoPodcastIds(modules),
    [modules]
  );
  const { videosWatchTimes, podcastListenTimes } = useWatchTime(
    videoIds,
    podcastIds
  );
  const meta = metaHeader || '';

  return (
    <>
      <CustomMeta
        title={title}
        description={description}
        image={metaImage || getMetaOgImage('customs', slug)}
        meta={meta}
      />
      {modules?.map((module) => (
        <ModuleComponent
          key={module?.id}
          videosWatchTimes={videosWatchTimes}
          podcastListenTimes={podcastListenTimes}
          {...module}
        />
      ))}
      <MetaFooter data={metaFooter} />
    </>
  );
}

export async function getStaticProps() {
  const configs = {
    revalidate: 60 * 5
  };

  try {
    const formUrl = `/${PAGE_TYPE?.CUSTOM?.toLowerCase()}/${STATIC_PAGES.HOME.toLowerCase()}.json?now=${Date.now()}`;
    const {
      data: {
        data: { pageV2: page }
      }
    } = await jsonDataApi.get(formUrl);

    if (!page) return { notFound: true };
    return {
      props: { page },
      ...configs
    };
  } catch (error) {
    if (error?.graphQLErrors?.length) {
      const code = get(error?.graphQLErrors?.[0], 'extensions.code');
      if (code === 'PAGE_NOT_FOUND') {
        return {
          notFound: true,
          ...configs
        };
      }
    }

    return { props: { page: {} }, ...configs };
  }
}

export default Modules;
