import parse from 'html-react-parser';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

function CustomMeta({ title = '', description = '', image = '', meta = '' }) {
  const router = useRouter();
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} key="og-title" />
      <meta property="og:description" content={description} key="og-desc" />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={router.asPath} key="og-url" />
      <meta property="og:site_name" content="Breitbart" key="og-site" />
      <meta name="twitter:title" content={title} key="tw-title" />
      <meta name="twitter:description" content={description} key="tw-desc" />
      <meta name="twitter:card" content="summary_large_image" key="tw-card" />
      <meta name="twitter:image" content={image} key="tw-image" />
      {parse(meta)}
    </Head>
  );
}

export default CustomMeta;
