import { useMatomo } from '@datapunt/matomo-tracker-react';
import toUpper from 'lodash/toUpper';
import { useEffect } from 'react';
import { gaEvent } from '../lib/gtag';
import { useApp } from '../providers/AppContext';

const useTrackPageEvent = ({
  type,
  id,
  slug,
  title,
  category,
  statusSuccess
}) => {
  const { trackEvent } = useMatomo();

  const {
    state: { currentUser, loading }
  } = useApp();

  useEffect(() => {
    if (loading) return;

    const eventData = {
      action: `VIEW_${toUpper(type)}`,
      email: currentUser?.email ?? '',
      instanceData: {
        id,
        slug,
        title
      },
      instanceId: id,
      instanceType: type,
      userId: currentUser?.id ?? ''
    };

    trackEvent({
      category,
      action: eventData?.action,
      name: JSON.stringify(eventData),
      value: statusSuccess
    });
    const gaEventData = {
      ...eventData
    };

    // Remove the email field from gaEventData
    delete gaEventData.email;
    gaEvent({
      category,
      action: gaEventData?.action,
      name: JSON.stringify(gaEventData),
      value: statusSuccess
    });
  }, [
    currentUser,
    loading,
    type,
    id,
    slug,
    title,
    category,
    statusSuccess,
    trackEvent
  ]);

  return null;
};

export default useTrackPageEvent;
