import { useLazyQuery } from '@apollo/client';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import {
  GET_PODCAST_WATCH_TIME,
  GET_VIDEO_WATCH_TIME
} from '../graphql/Queries';

const useWatchTime = (videoIds, podcastIds) => {
  const { data: sessionData } = useSession();
  const [videoValues, setVideoValues] = useState({});
  const [podcastValues, setPodcastValues] = useState({});
  const accessToken = sessionData?.accessToken;
  const [fetchVideoWatchTime, response] = useLazyQuery(GET_VIDEO_WATCH_TIME, {
    fetchPolicy: 'network-only',
    onError: () => {
      // do nothing
    }
  });
  const [fetchPodcastWatchTime] = useLazyQuery(GET_PODCAST_WATCH_TIME, {
    fetchPolicy: 'network-only',
    onError: () => {
      // do nothing
    }
  });

  useEffect(() => {
    if (accessToken && (videoIds?.length > 0 || podcastIds?.length > 0)) {
      if (videoIds?.length > 0) {
        fetchVideoWatchTime({
          variables: {
            videoIds
          },
          context: {
            headers: {
              token: accessToken
            }
          }
        }).then(({ data }) => {
          if (data?.videosProgress) {
            setVideoValues(
              Object.fromEntries(
                data?.videosProgress?.map((video) => [
                  video?.videoId,
                  video?.watchTime
                ])
              )
            );
          }
        });
      }
      if (podcastIds?.length > 0) {
        fetchPodcastWatchTime({
          variables: {
            podcastIds
          },
          context: {
            headers: {
              token: accessToken
            }
          }
        }).then(({ data }) => {
          if (data?.podcastsProgress) {
            setPodcastValues(
              Object.fromEntries(
                data?.podcastsProgress?.map((podcast) => [
                  podcast?.podcastId,
                  podcast?.playTime
                ])
              )
            );
          }
        });
      }
    }
  }, [
    accessToken,
    videoIds,
    fetchVideoWatchTime,
    fetchPodcastWatchTime,
    podcastIds
  ]);

  return {
    videosWatchTimes: videoValues,
    podcastListenTimes: podcastValues,
    response
  };
};

export default useWatchTime;
